@import 'sprites';

$break-small: 920px;

@font-face {
    font-family: 'PixelGosub';
    src: url('../fonts/PixelGosub.eot');
    src: url('../fonts/PixelGosub.eot') format('embedded-opentype'),
         url('../fonts/PixelGosub.woff2') format('woff2'),
         url('../fonts/PixelGosub.woff') format('woff'),
         url('../fonts/PixelGosub.ttf') format('truetype'),
         url('../fonts/PixelGosub.svg#PixelGosub') format('svg');
}

body {
	width:  100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

#game {
	height: 100%;
	width: 100%;
	position: absolute; 
	overflow: hidden;
	background-color: #a8ecf0;
}
#world { 
	margin: 0; padding: 0; height: 100%; width: 100%; position: absolute;
	bottom: 0; left: 0; z-index: 0;
	transform: translateZ(0);
}
.gauge {
	margin: 0; padding: 0; height: 143px; width: 220px; text-align: right; font-size: 2em;
	font-weight: bold; position: absolute; top: 0px; right: 100px; z-index: 1000;
	position: absolute; font-family: 'PixelGosub';
}
.hand {
	@include sprite($hand);
	position: absolute;
	left: -4px;
	transform: translate(-360px, 0) scale(.5, .5);
	top: 18px;
	@media screen and (min-width: $break-small) {
		top: 56px;
		transform: translate(-360px, 0) scale(1, 1);
  }
	&.open {
		transform: translate(-100px, 0) scale(.5, .5);
		@media screen and (min-width: $break-small) {
			transform: translate(0px, 0) scale(1, 1);
		}
	}
	.logo {
		width: 196px;
		height: 105px;
		position: absolute;
		left: 161px;
		top: 0px;
		&.a-and-w{@include sprite($a-and-w)}
		&.amir{@include sprite($amir)}
		&.aqua-lunch{@include sprite($aqua-lunch)}
		&.basha{@include sprite($basha)}
		&.bento-nouveau{@include sprite($bento-nouveau)}
		&.bubble-tease{@include sprite($bubble-tease)}
		&.burger-king{@include sprite($burger-king)}
		&.crepe-gerard{@include sprite($crepe-gerard)}
		&.culture{@include sprite($culture)}
		&.duluth{@include sprite($duluth)}
		&.edo-japan{@include sprite($edo-japan)}
		&.experience-teriyaki{@include sprite($experience-teriyaki)}
		&.jugo-juice{@include sprite($jugo-juice)}
		&.kernels-popcorn{@include sprite($kernels-popcorn)}
		&.kim-chi{@include sprite($kim-chi)}
		&.kojax{@include sprite($kojax)}
		&.kunbg-pao-wok{@include sprite($kunbg-pao-wok)}
		&.la-belle-province{@include sprite($la-belle-province)}
		&.la-cremiere{@include sprite($la-cremiere)}
		&.mmmuffins{@include sprite($mmmuffins)}
		&.nu-do{@include sprite($nu-do)}
		&.quiznos{@include sprite($quiznos)}
		&.subway{@include sprite($subway)}
		&.taco-supreme{@include sprite($taco-supreme)}
		&.tandori{@include sprite($tandori)}
		&.thai-express{@include sprite($thai-express)}
		&.tiki-ming{@include sprite($tiki-ming)}
		&.trattoria-mangione{@include sprite($trattoria-mangione)}
		&.valentine{@include sprite($valentine)}
		&.vanelli{@include sprite($vanelli)}
		&.vie-and-nam{@include sprite($vie-and-nam)}
	}
}

.gaugeSprite {
	margin: 0; padding: 0; z-index: 1000; position: absolute;
}

#coinNumber {
	@include sprite($bg_score);
	left: 50%;
	line-height: 210px;
	text-align: center;
	color: #ff589a;
	font-size: 50px;
	margin-left: -115px;
	top: -70px;
	transform: scale(.5, .5);
	@media screen and (min-width: $break-small) {
		top: -5px;
	transform: scale(1, 1);
  }
}
.timer {
	position: absolute;
	font-family: 'PixelGosub';
	color: #fff;
	font-size: 35px;
	text-shadow: 4px 4px 0px #86bdc0;
	right: 10px;
	top: 47px;
	@media screen and (min-width: $break-small) {
		font-size: 50px;
		top: 70px;
		right: 50px;
	}
}
.figure {
	margin: 0; padding: 0; z-index: 99; position: absolute;
	&.mario {
		transform: translateZ(0);
		.score {
			opacity: 0;
			position: absolute;
			top: -20px;
			left: 50%;
			transform: translate(-50%, 0);
			font-family: 'PixelGosub';
			font-size: 20px;
			color: #feff9d;
			text-shadow: 2px 2px 0px #d1d266;
			padding-right: 20px;
			.text {
			}
			.pts {
				position: absolute;
				top: 3px;
				right: 0px;
				font-size: 10px;
			}
		}
	}
}
.matter {
	margin: 0; padding: 0; z-index: 95; position: absolute; width: 32px; height: 32px;
}
#game_buttons {
	position: absolute;
	bottom: 7px;
	text-align: center;
	line-height: 50px;
	display: none;
	height: 50px;
	width: 100%;
	&.active {
		display: block;
	}
	.button {
		user-select: none;
		width: 70px;
		height: 50px;
		display: inline-block;
		background: #ff589a;
		color: #fff;
		position: absolute;
		font-size: 30px;
		&#aButton { display: none }
		&#dButton { display: none }
		&#uButton { right: 5px; }
		&#lButton { left: 5px; }
		&#rButton { left: 85px; }
	}

}