$kim-chi: -0px -0px 204px 113px;
$a-and-w: -0px -113px 204px 113px;
$aqua-lunch: -0px -226px 204px 113px;
$basha: -0px -339px 204px 113px;
$bento-nouveau: -0px -452px 204px 113px;
$vanelli: -0px -565px 204px 113px;
$bubble-tease: -0px -678px 204px 113px;
$burger-king: -0px -791px 204px 113px;
$crepe-gerard: -0px -904px 204px 113px;
$culture: -0px -1017px 204px 113px;
$duluth: -0px -1130px 204px 113px;
$edo-japan: -0px -1243px 204px 113px;
$experience-teriyaki: -0px -1356px 204px 113px;
$hand: -0px -1469px 365px 113px;
$jugo-juice: -0px -1582px 204px 113px;
$kernels-popcorn: -0px -1695px 204px 113px;
$amir: -0px -1808px 204px 113px;
$kojax: -0px -1921px 204px 113px;
$kunbg-pao-wok: -0px -2034px 204px 113px;
$la-belle-province: -0px -2147px 204px 113px;
$la-cremiere: -0px -2260px 204px 113px;
$mmmuffins: -0px -2373px 204px 113px;
$nu-do: -0px -2486px 204px 113px;
$quiznos: -0px -2599px 204px 113px;
$subway: -0px -2712px 204px 113px;
$taco-supreme: -0px -2825px 204px 113px;
$tandori: -0px -2938px 204px 113px;
$thai-express: -0px -3051px 204px 113px;
$tiki-ming: -0px -3164px 204px 113px;
$trattoria-mangione: -0px -3277px 204px 113px;
$valentine: -0px -3390px 204px 113px;
$vie-and-nam: -0px -3503px 204px 113px;
$bg_score: -0px -3616px 228px 151px;

@mixin sprite-width($sprite) {
  width: nth($sprite, 3);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 4);
}

@function sprite-width($sprite) {
  @return nth($sprite, 3);
}

@function sprite-height($sprite) {
  @return nth($sprite, 4);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 1);
  $sprite-offset-y: nth($sprite, 2);
  background-position: $sprite-offset-x $sprite-offset-y;
}

@mixin sprite($sprite, $display: block) {
  @include sprite-position($sprite);
  background-repeat: no-repeat;
  overflow: hidden;
  display: $display;
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

.icon {
  background-image: url("../img/sprites.png");
}